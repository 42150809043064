import * as React from 'react';
import { GridItem } from '@chakra-ui/react';
import { Grid8 } from '@components/Grid';
import { TextModule } from '.';

export interface SectionModuleProps {
	body: any;
	isWideBody?: boolean;
	title: any;
}

export const SectionModule: React.FC<SectionModuleProps> = (props) => {
	return (
		<Grid8 flush="vertical">
			<GridItem gridColumn={{ base: '1/-1', sm: props.isWideBody ? '1/span 3' : '1/span 4', md: props.isWideBody ? '3/span 1' : '3/span 2' }}>
				<TextModule blocks={props.title} />
			</GridItem>
			<GridItem gridColumn={{ base: '1/-1', sm: props.isWideBody ? '4/-1' : '5/-1', md: props.isWideBody ? '5/span 4' : '6/span 3' }}>
				<TextModule blocks={props.body} />
			</GridItem>
		</Grid8>
	);
};
