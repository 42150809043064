import { Box, Image, Center, Circle, Grid, GridItem } from '@chakra-ui/react';
import { MotionBox } from '@components/MotionBox';
import { Play } from '@components/Icons';
import { SanityImageAsset } from '@sanity/asset-utils';
import { useGridAlignment, GridAlignmentHeight } from '@components/GridAlignmentWrapper';
import * as React from 'react';
import AspectRatioWrapper from '@components/AspectRatioWrapper';
import TransitionBox from '@components/TransitionBox';
import YouTube from 'react-youtube';

const VideoOverlay = (props: { thumbnailUrl: string; onClick: () => void; isPlaying?: boolean; hasPlayed?: boolean }) => {
	const [isHovering, setIsHovering] = React.useState(false);

	return (
		<MotionBox
			animate={{ visibility: !props.isPlaying ? 'visible' : 'hidden' }}
			boxSize="100%"
			cursor="pointer"
			initial={{ visibility: 'hidden' }}
			onClick={props.onClick}
			position="relative"
		>
			{!props.isPlaying && !props.hasPlayed && (
				<Box boxSize="100%" position="absolute">
					<Image src={props.thumbnailUrl} alt="" boxSize="100%" objectFit="cover" objectPosition="center center" />
				</Box>
			)}

			<Center boxSize="100%" position="absolute">
				<Circle
					_hover={{ bg: 'white' }}
					bg="rgba(0,0,0,0.25)"
					border={'0.2em solid white'}
					onMouseEnter={() => setIsHovering(true)}
					onMouseLeave={() => setIsHovering(false)}
					padding="4"
					role="group"
					size="5em"
				>
					<Play fontSize="2.5em" marginLeft={'0.2em'} fill={isHovering ? 'black' : 'white'} />
				</Circle>
			</Center>
		</MotionBox>
	);
};

export interface YoutubeModuleProps {
	autoPlay?: boolean;
	background?: boolean;
	gridHeight?: GridAlignmentHeight;
	height?: number;
	isCarouselItem?: boolean;
	thumbnailAsset?: SanityImageAsset;
	width?: number;
	youtubeId: string;
}

export const YoutubeModule: React.FC<YoutubeModuleProps> = (props) => {
	const [hasPlayed, setHasPlayed] = React.useState(false);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [videoElement, setVideoElement] = React.useState<any>();

	// const carouselContext = useCarouselContext();
	const gridAlignment = useGridAlignment();

	const thumbnailUrl = `${props.thumbnailAsset?.url}?q=90&w=1920`;
	const aspectRatio = props.width / props.height;
	const effectiveGridHeight = gridAlignment?.gridHeight ?? props.gridHeight;

	const playerOpts = {
		height: '100%',
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			// autoplay: 1,
			// controls: 0
		},
		width: '100%',
	};

	const onLoaded = React.useCallback(
		(event) => {
			setIsLoaded(true);
			setVideoElement(event.target);
		},
		[setIsLoaded],
	);

	const play = React.useCallback(() => {
		if (videoElement && !isPlaying) {
			const playPromise = videoElement.playVideo();
			setIsPlaying(true);
			setHasPlayed(true);

			// if (carouselContext) {
			//   carouselContext.requestPause();
			// }
		}
	}, [videoElement, setIsPlaying, setHasPlayed, isPlaying]);

	const stop = React.useCallback(() => {
		if (videoElement && isPlaying) {
			videoElement.stopVideo();
			setIsPlaying(false);

			// if (carouselContext) {
			//   carouselContext.requestPlay();
			// }
		}
	}, [videoElement, setIsPlaying, isPlaying]);

	const onVideoPreviewClick = React.useCallback(() => {
		!isPlaying ? play() : stop();
	}, [isPlaying, play, stop]);

	return (
		<AspectRatioWrapper ratio={effectiveGridHeight == 'row' ? undefined : aspectRatio}>
			<TransitionBox isLoaded={isLoaded}>
				<Grid boxSize="100%" gridTemplateRows="1fr" gridAutoRows="100%" gridAutoColumns="100%">
					<GridItem gridColumn="1/-1" gridRow="1" width="100%">
						<YouTube containerClassName={'youtubeVideo'} videoId={props.youtubeId} onReady={onLoaded} opts={playerOpts} />
					</GridItem>
					{!props.background && (
						<GridItem gridArea="1/-1" gridRow="1">
							<VideoOverlay thumbnailUrl={thumbnailUrl} onClick={onVideoPreviewClick} isPlaying={isPlaying} hasPlayed={hasPlayed} />
						</GridItem>
					)}
				</Grid>
			</TransitionBox>
		</AspectRatioWrapper>
	);
};

YoutubeModule.defaultProps = {
	width: 1920,
	height: 1080,
};
