import { Asset } from '@lib/types';
import { ExternalLinkWrapper } from '@components/ExternalLink';
import { useGridAlignment } from '@components/GridAlignmentWrapper';
import * as React from 'react';
import StyledSanityImage, { ImageAlignment } from '../SanityImage';

export interface ImageModuleProps {
	alignment?: ImageAlignment;
	asset: Asset;
	externalLink?: string;
	title?: string;
}

export const ImageModule: React.FC<ImageModuleProps> = (props) => {
	const gridAlignment = useGridAlignment();

	if (!props.asset) return null;

	return (
		<ExternalLinkWrapper href={props.externalLink}>
			<StyledSanityImage asset={props.asset} title={props.title} alignment={props.alignment ?? gridAlignment?.gridJustify} />
		</ExternalLinkWrapper>
	);
};
