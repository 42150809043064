import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { VimeoModule } from './VimeoModule';
import { VideoModule, MuxVideo } from './VideoModule';
import { ImageModule } from './ImageModule';
import Carousel, { CarouselItem } from '@components/Carousel';

export interface CarouselModuleProps {
	arrowColor?: string;
	autoplay?: boolean;
	defaultAspectRatio?: number;
	rowSpacing?: string;
	slides: any[];
}

export const CarouselModule: React.FC<CarouselModuleProps> = (props) => {
	// mk: from Taylor's older PR
	const [allowAutoPlay, setAllowAutoPlay] = React.useState(props.autoplay);
	const deactivateAutoPlay = React.useCallback(() => {
		setAllowAutoPlay(false);
	}, [setAllowAutoPlay]);

	// get the maximum aspect ratio to use from the slides, smaller aspect ratio slides will be cropped
	const maximumAspectRatio = props.slides.reduce((highest, slide) => {
		if (slide._type == 'figureImage') {
			// grab aspect ratio from sanity image asset metadata
			const imageAspectRatio = slide.asset?.metadata?.dimensions?.aspectRatio;

			if (imageAspectRatio > highest) {
				return imageAspectRatio;
			}
		}
		else if (slide._type == 'figureVimeo') {
			if (slide.width != undefined && slide.height != undefined && slide.height != 0) {
				// grab aspect ratio from width and height fields
				const vimeoAspectRatio = slide.width / slide.height;
				if (vimeoAspectRatio > highest) {
					return vimeoAspectRatio;
				}
			} else if (props.defaultAspectRatio > highest) {
				// if those dont exist, use default
				return props.defaultAspectRatio;
			}
		}
		else if (slide._type == 'figureVideo') {
			if (slide.height !== undefined && slide.height !== 0 && slide.width !== undefined && slide.width !== 0) {
				const explicitRatio = slide.width / slide.height;
				if (explicitRatio > highest) {
					return explicitRatio;
				}
			} else if (slide.muxVideo?.asset?.data) {
				const inferedMuxRatio = slide.muxVideo.asset.data.aspect_ratio.split(':').reduce((prev, current) => prev / current);
				if (inferedMuxRatio > highest) {
					return inferedMuxRatio;
				}
			} else if (props.defaultAspectRatio > highest) {
				return props.defaultAspectRatio;
			}
		}
		else {
			return 1;
		}

		return highest;
	}, 0);

	const slides = props.slides?.map((slide, i) => {
		let inner: React.ReactNode;

		switch (slide._type) {
			case 'figureVideo':
			case 'figureVimeo': {
				if (allowAutoPlay) {
					deactivateAutoPlay();
				}

				if (slide.muxVideo?.asset) {
					inner = (
						<MuxVideo
							autoPlay={slide.playerOptions == 'autoplay'}
							background={slide.playerOptions == 'background'}
							key={slide._key}
							muxVideoAsset={slide.muxVideo?.asset}
						/>
					);
				}
				else if (slide.vimeoId && slide.vimeoId != '') {
					// if (allowAutoPlay) deactivateAutoPlay();

					inner = (
						<VimeoModule
							height={slide.height}
							key={slide._key}
							playerOptions={slide.playerOptions}
							position={slide.gridColumn}
							size={slide.size}
							span={slide.gutterSpan}
							vimeoId={slide.vimeoId}
							width={slide.width}
						/>
					);
				}
				else if (slide.video?.url) {
					inner = <VideoModule url={slide.video.url} />;
				}
				break;
			}

			case 'figureImage':
				inner = <ImageModule key={slide._key} asset={slide.asset} externalLink={slide.externalLink} title={slide.title} />;
				break;

			default:
				console.warn(`unknown carousel slide type "${slide._type}"`);
				return;
		}

		return <CarouselItem key={i}>{inner}</CarouselItem>;
	});

	return (
		<Box marginBottom={{ base: 12, sm: 'unset' }}>
			<Carousel aspectRatio={maximumAspectRatio} autoplay={allowAutoPlay} arrowColor={props.arrowColor}>
				{slides}
			</Carousel>
		</Box>
	);
};

CarouselModule.defaultProps = {
	defaultAspectRatio: 16 / 9,
};
