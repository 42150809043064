import * as React from 'react';
import SanityBlockContent from '@sanity/block-content-to-react';
import { Text, Link, Heading } from '@chakra-ui/react';
import TextBox from '@components/TextBox';

const fontSizeMap = {
	default: 'md',
	large: 'lg',
	small: 'sm',
};

export interface TextModuleProps {
	blocks: any[];
	fontSize?: keyof typeof fontSizeMap;
}

export const TextModule: React.FC<TextModuleProps> = (props) => {
	return (
		<TextBox fontSize={fontSizeMap[props.fontSize]}>
			<SanityBlockContent
				blocks={props.blocks ?? []}
				serializers={{
					marks: {
						// eslint-disable-next-line react/display-name
						subtle: function BlockSubtle(props) {
							return (
								<Text as="span" opacity=".5">
									{props.children}
								</Text>
							);
						},
						link: function BlockLink(props) {
							const isExternal = !props.mark.href?.includes('buck.co');

							return (
								<Link href={props.mark.href} isExternal={isExternal} borderBottom="solid 2px black">
									{props.children}
								</Link>
							);
						},
						color: (props) => props.children,
					},
					block: function Block(props) {
						if (props.node.style == 'heading')
							return (
								<Heading sx={{ ':not(:first-child)': { mt: 12 } }} mb={4} size="md">
									{props.children}
								</Heading>
							);
						else if (props.node.style == 'small')
							return (
								<TextBox fontSize="sm" sx={{ ':not(:first-child)': { mt: 12 } }}>
									{props.children}
								</TextBox>
							);
						else if (props.node.style == 'large')
							return (
								<TextBox fontSize="lg" sx={{ ':not(:first-child)': { mt: 12 } }}>
									{props.children}
								</TextBox>
							);

						return SanityBlockContent.defaultSerializers.types.block(props);
					},
				}}
			/>
		</TextBox>
	);
};

TextModule.defaultProps = {
	fontSize: 'default',
};
