import * as React from 'react';
import * as ui from '@chakra-ui/react';
import { ImageAlignment } from '../SanityImage';
import { Asset } from '@lib/types';
import { HandPaper } from '@components/Icons';

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { urlFor } from '@lib/sanity';
import { keyframes } from '@chakra-ui/react';

export interface ImageComparisionModuleProps {
	alignment?: ImageAlignment;
	assetA: Asset;
	assetB: Asset;
	autoAnimate?: boolean;
	externalLink?: string;
	resumeAnimation?: boolean;
	startPosition?: 'fullA' | 'fullB' | 'half' | string;
	title?: string;
}

const handleAnimationKeyframes = keyframes`
  from {transform: scale(1);}
  to {transform: scale(1.2)}
`;

const handleAnimation = `${handleAnimationKeyframes} infinite .5s alternate`;

const Handle = () => {
	const [hasInteracted, setHasInteracted] = React.useState(false);
	return (
		<ui.VStack h="100%" cursor="ew-resize" pointerEvents="none" spacing="0">
			<ui.Box bg="white" flex="1" h="100%" width="2px" pointerEvents="auto" />
			<ui.Center
				_groupHover={{ transform: 'scale(1.2)' }}
				animation={!hasInteracted && handleAnimation}
				bg="white"
				borderRadius="50%"
				fontSize="1em"
				onMouseDown={() => setHasInteracted(true)}
				p="2"
				pointerEvents="auto"
				transform={hasInteracted && 'scale(1)'}
				transitionDuration=".3s"
			>
				<HandPaper color="black" />
			</ui.Center>
			<ui.Box bg="white" h="4" width="2px" pointerEvents="auto" />
		</ui.VStack>
	);
};

export const ImageComparisionModule: React.FC<ImageComparisionModuleProps> = (props) => {
	const initialPosition = React.useMemo(() => {
		if (props.startPosition == 'fullA') return 0;
		else if (props.startPosition == 'fullB') return 100;
		else return 50;
	}, [props.startPosition]);

	return (
		<ui.Box boxSize="100%" role="group">
			<ReactCompareSlider
				handle={<Handle />}
				itemOne={<ReactCompareSliderImage src={urlFor(props.assetA).maxWidth(1000).quality(95).auto('format').url()} alt="Image one" />}
				itemTwo={<ReactCompareSliderImage src={urlFor(props.assetB).maxWidth(1000).quality(95).auto('format').url()} alt="Image two" />}
				position={initialPosition}
			/>
		</ui.Box>
	);
};

export default ImageComparisionModule;
