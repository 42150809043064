import * as React from 'react';
import { Box, Heading, Text, GridItem } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { Grid8 } from '@components/Grid';
import { TextModule } from '.';

export interface ICreditsModuleRole {
	name: string;
	people: {
		name: string;
	}[];
}

export const CreditRole: React.FC<ICreditsModuleRole> = (props) => {
	return (
		<Box
			mb={4}
			css={css`
				break-inside: avoid;
				page-break-inside: avoid;
			`}
		>
			<Heading size="sm" mb={2} fontWeight="100" opacity={0.5}>
				{props.name}
			</Heading>
			{props.people.map((person) => {
				return <Text key={person.name}>{person.name}</Text>;
			})}
		</Box>
	);
};

interface ICreditsModuleVendor {
	name: string;
	roles: ICreditsModuleRole[];
}

export interface CreditsProps {
	vendor: ICreditsModuleVendor;
	expanded: boolean;
}

export const CreditsVendor: React.FC<CreditsProps> = (props) => {
	const isExpanded = props.expanded && 'is-expanded';

	return (
		<>
			<GridItem gridColumn={{ base: '1/span 2', sm: '3/span 2' }}>
				<Heading size="sm" mb={4} fontWeight="500">
					{props.vendor.name}
				</Heading>
			</GridItem>
			<GridItem gridColumn={{ base: '3/-1', sm: '5/-1' }} mb={16} sx={{ columnCount: { base: 1, sm: 2 }, columnGap: 4 }}>
				{props.vendor.roles.map((role) => {
					return <CreditRole key={role.name} name={role.name} people={role.people} />;
				})}
			</GridItem>
		</>
	);
};

export interface CreditsModuleProps {
	body?: any;
	title?: string;
	vendors?: ICreditsModuleVendor[];
}

export const CreditsModuleVendors: React.FC<{ vendors?: ICreditsModuleVendor[] }> = (props) => {
	return (
		<>
			{props.vendors?.map((vendor) => {
				return <CreditsVendor key={vendor.name} vendor={vendor} expanded={true} />;
			})}
		</>
	);
};

export const CreditsModule: React.FC<CreditsModuleProps> = (props) => {
	// const [expanded, setCreditsExpanded] = React.useState(false);

	// const onRevealClick = React.useCallback(() => {
	//   setCreditsExpanded(!expanded);
	// }, [expanded]);

	return (
		<Grid8 rowSpacing={'flush'} flush="vertical">
			<GridItem gridColumn={{ base: '1/span 3', sm: '3/span 2' }} mb={8} opacity={0.5}>
				{props.title}
			</GridItem>
			{props.vendors?.length > 0 && <CreditsModuleVendors vendors={props.vendors} />}
			{!(props.vendors?.length > 0) && props.body && (
				<GridItem gridColumn={{ base: '1/-1', sm: '5/-1' }} mb={16}>
					<TextModule blocks={props.body} />
				</GridItem>
			)}
		</Grid8>
	);
};

CreditsModule.defaultProps = {
	title: 'Credits',
};

export default CreditsModule;
