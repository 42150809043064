import * as React from 'react';
import { GridItem } from '@chakra-ui/react';

import { VimeoModule, VimeoModuleProps } from './VimeoModule';
import { YoutubeModule, YoutubeModuleProps } from './YoutubeModule';
import { CarouselModule, CarouselModuleProps } from './CarouselModule';
import { VideoModule, VideoModuleProps, MuxVideo } from './VideoModule';
import { TextModule, TextModuleProps } from './TextModule';
import { AssetModule, AssetModuleProps } from './AssetModule';
import { ImageModule, ImageModuleProps } from './ImageModule';
import { SectionModule, SectionModuleProps } from './SectionModule';
import { CreditsModule, CreditsModuleProps } from './CreditsModule';
import { SuperGridModule } from './SuperGridModule';
import { ImageComparisionModule, ImageComparisionModuleProps } from './ImageComparisonModule';

import { ComponentFactory } from '@components/components/interactive';
import { GridAlignmentWrapper } from '@components/GridAlignmentWrapper';
import { GridItemProps } from '@chakra-ui/react';
import { inversePadding } from '@theme';
import { getGridColumn, LayoutItem, layoutItemsToRows, columnToNumber, spanToNumber } from '@lib/layout';
import { GridCellContext } from '@components/GridCellContext';

export { CarouselModule, VimeoModule, VideoModule, TextModule, AssetModule, SuperGridModule, CreditsModule, ImageModule };
export type ModulesType =
	| AssetModuleProps
	| CarouselModuleProps
	| CreditsModuleProps
	| ImageModuleProps
	| SectionModuleProps
	| TextModuleProps
	| VideoModuleProps
	| VimeoModuleProps
	| YoutubeModuleProps
	| ImageComparisionModuleProps;

export type ModulesItem = ModulesType & LayoutItem & { _type: string };

export interface ModulesProps {
	expandTextWherePossible?: boolean;
	modules: ModulesItem[];
	preventContentOverlapWithLogoAndMenu?: boolean;
	restrictLayout?: boolean;
	rowSpacing?: string;
}

export const Modules: React.FC<ModulesProps> = (props) => {
	const rows = layoutItemsToRows(props.modules);

	return (
		<>
			{rows.map((row, rowI) => {
				let rowContentIndex = 0;
				const rowContentCount = row.items.filter((item) => item._type != 'moduleSpacer').length;

				return (
					<React.Fragment key={rowI}>
						{row.items?.map((module) => {
							let inner: React.ReactNode;
							const gridItemProps: GridItemProps = {
								gridColumn: {
									base: '1/-1',
									sm: getGridColumn({ gridColumn: module.gridColumn, gridSpan: module.gridSpan }),
									md: getGridColumn({ gridColumn: module.gridColumn, gridSpan: module.gridSpan }),
								},
							};

							const isFirstItemInRow = rowContentIndex == 0;
							const isOnlyItemInRow = rowContentCount == 1;

							if (module.gridColumn && module.gridColumn != 'full' && module._type != 'moduleCarousel') {
								if (columnToNumber(module.gridColumn) <= 4) {
									gridItemProps.gridColumn['sm'] = props.preventContentOverlapWithLogoAndMenu ? '3/span 3' : '1/span 4';
								} else {
									gridItemProps.gridColumn['sm'] = props.preventContentOverlapWithLogoAndMenu ? '6/span 3' : '5/span 4';
								}
							}

							if (props.expandTextWherePossible) {
								if (module._type == 'moduleText' && isOnlyItemInRow) {
									gridItemProps.gridColumn['sm'] = props.preventContentOverlapWithLogoAndMenu ? '3/-1' : '3/-1';
								}
							}

							if (module.gridSpan == 'full') {
								gridItemProps.mx = inversePadding;
							}

							if (module._type == 'feedSpacer') {
								return <GridItem key={module._key} {...gridItemProps}></GridItem>;
							}

							if (module.isAvoidLogo) {
								// this should probably do a Math.max to get at minimum col3 or at max whatever value about col3 the module.gridColumn has set
								gridItemProps.gridColumn['sm'] = getGridColumn({ gridColumn: 'col3', gridSpan: module.gridSpan });
							}

							if (props.restrictLayout) {
								gridItemProps.gridColumn['base'] = '1/-1';
								gridItemProps.gridColumn['sm'] = '3/span 4';
								gridItemProps.gridColumn['md'] = '3/span 4';

								if (module.fullBleed) {
									gridItemProps.gridColumn['sm'] = undefined;
									gridItemProps.gridColumn['md'] = undefined;
									gridItemProps.mx = inversePadding;
								}
							}

							if (module._type == 'moduleText' && module.isMiddleAlign) {
								gridItemProps.display = 'flex';
								gridItemProps.flexDirection = 'column';
								gridItemProps.justifyContent = 'center';
							}

							// if (module.muxVideo) {
							// 	module.muxVideo.asset.playbackId = 'rJE01mdCd59G00LAVeI6plZVtlrna9fZV6WcKXcKXKcUE?quality=1080p';
							// }

							switch (module._type) {
								case 'moduleVideo': {
									gridItemProps.gridColumn['base'] = '1/-1';

									if (isOnlyItemInRow) {
										gridItemProps.gridColumn['sm'] = '1/-1';
									}

									if (module.muxVideo?.asset) {
										inner = (
											<GridAlignmentWrapper
												gridAlign={module.gridAlign}
												gridJustify={module.gridJustify}
												gridHeight={module.gridHeight}
											>
												<MuxVideo
													autoPlay={module.playerOptions == 'autoplay'}
													background={module.playerOptions == 'background'}
													externalLink={module.externalLink}
													key={module._key}
													muxVideoAsset={module.muxVideo?.asset}
													thumbnailAsset={module.thumbnail?.asset}
												/>
											</GridAlignmentWrapper>
										);
									} else if (module.vimeoId && module.vimeoId != '') {
										inner = (
											<GridAlignmentWrapper
												gridAlign={module.gridAlign}
												gridJustify={module.gridJustify}
												gridHeight={module.gridHeight}
											>
												<VimeoModule
													key={module._key}
													vimeoId={module.vimeoId}
													size={module.size}
													width={module.width}
													height={module.height}
													span={module.gutterSpan}
													position={module.gridColumn}
													playerOptions={module.playerOptions}
												/>
											</GridAlignmentWrapper>
										);
									} else if (module.youtubeId && module.youtubeId != '') {
										inner = (
											<GridAlignmentWrapper
												gridAlign={module.gridAlign}
												gridJustify={module.gridJustify}
												gridHeight={module.gridHeight}
											>
												<YoutubeModule
													youtubeId={module.youtubeId}
													width={module.width}
													height={module.height}
													background={module.playerOptions == 'background'}
													autoPlay={module.playerOptions == 'autoplay'}
													thumbnailAsset={module.thumbnail?.asset}
												/>
											</GridAlignmentWrapper>
										);
									} else if (module.video?.url) {
										inner = (
											<GridAlignmentWrapper
												gridAlign={module.gridAlign}
												gridJustify={module.gridJustify}
												gridHeight={module.gridHeight}
											>
												<VideoModule url={module.video.url} />
											</GridAlignmentWrapper>
										);
									}
									break;
								}
								case 'moduleText':
									inner = <TextModule key={module._id} blocks={module.body}></TextModule>;
									break;
								case 'moduleImage':
									if (isOnlyItemInRow && module.gridSpan == 'span6') {
										gridItemProps.gridColumn['sm'] = '1/-1';
									}
									inner = (
										<GridAlignmentWrapper
											gridAlign={module.gridAlign}
											gridJustify={module.gridJustify}
											gridHeight={module.gridHeight}
										>
											<ImageModule
												key={module._key}
												asset={module.image}
												externalLink={module.externalLink}
												title={module.title}
											/>
										</GridAlignmentWrapper>
									);
									break;
								case 'moduleImageComparison':
									inner = (
										<GridAlignmentWrapper
											gridAlign={module.gridAlign}
											gridJustify={module.gridJustify}
											gridHeight={module.gridHeight}
										>
											<ImageComparisionModule
												key={module._id}
												assetA={module.imageA}
												assetB={module.imageB}
												startPosition={module.startPosition}
												autoAnimate={module.autoAnimate}
												resumeAnimation={module.resumeAnimation}
											/>
										</GridAlignmentWrapper>
									);
									break;
								case 'moduleSupergrid':
									gridItemProps.gridColumn = '1/-1';
									gridItemProps.mx = inversePadding;
									inner = <SuperGridModule key={module._key} rowSpacing={module.rowSpacing} modules={module.modules} />;
									break;
								case 'moduleCarousel':
									if (module.slides?.length > 0) {
										gridItemProps.gridColumn['base'] = '1/-1';
										// gridItemProps.gridColumn['sm'] = '1/-1';
										inner = (
											<CarouselModule
												rowSpacing={module.rowSpacing}
												slides={module.slides}
												autoplay={module.autoplay}
												arrowColor={module.arrowColor}
											/>
										);
									}
									break;
								case 'moduleCredits':
									gridItemProps.gridColumn = '1/-1';
									gridItemProps.mx = inversePadding;
									inner = <CreditsModule title={module.title} body={module.body} vendors={module.vendors} />;
									break;
								case 'moduleComponent': {
									// gridItemProps.gridColumn = '1/-1';
									// gridItemProps.mx = inversePadding;

									const { _key, component, ...rest } = module;
									const width = component.preferredWidth ?? 1920;
									const height = component.preferredHeight ?? 1080;
									inner = <ComponentFactory key={_key} id={component.id} width={width} height={height} {...rest} />;
									break;
								}
								case 'moduleSection': {
									gridItemProps.gridColumn = '1/-1';
									gridItemProps.mx = inversePadding;
									inner = (
										<SectionModule key={module._key} title={module.title} body={module.body} isWideBody={module.isWideBody} />
									);
									break;
								}
								default:
									console.warn(`unknown module type "${module._type}"`);
									return;
							}

							rowContentIndex++;

							return (
								<GridCellContext.Provider value={{ gridSpan: module.gridSpan }} key={module._key}>
									<GridItem {...gridItemProps} position="relative">
										{/* <ui.Box
                      as="pre"
                      zIndex="99999"
                      color="red.500"
                      bg="white"
                      opacity={0.2}
                      _hover={{ opacity: 0.8 }}
                      position="absolute"
                      top="0"
                      left="0"
                      fontSize="8px"
                      p="2"
                    >
                      {JSON.stringify(
                        {
                          ...gridItemProps,
                          studioGridSpan: module.gridSpan,
                          studioGridColumn: module.gridColumn,
                          type: module._type,
                          debug: module.debug,
                          rowCount: row.count
                        },
                        undefined,
                        2
                      )}
                    </ui.Box> */}
										{inner}
									</GridItem>
								</GridCellContext.Provider>
							);
						})}
					</React.Fragment>
				);
			})}
		</>
	);
};

Modules.defaultProps = {
	expandTextWherePossible: true,
};

export default Modules;
