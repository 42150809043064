import { Grid8 } from '@components/Grid';
import { Modules, ModulesItem } from './index';
import * as React from 'react';

export interface SuperGridModuleProps {
	modules: ModulesItem[];
	rowSpacing?: 'equal' | 'flush';
}

export const SuperGridModule: React.FC<SuperGridModuleProps> = (props) => {
	return (
		<>
			<Grid8 rowSpacing={props.rowSpacing} flush="vertical">
				<Modules modules={props.modules} expandTextWherePossible={false} />
			</Grid8>
		</>
	);
};

SuperGridModule.defaultProps = {
	rowSpacing: 'equal',
};
